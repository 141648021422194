.navbar-default {
  padding: 0;

  border-radius: 0;
  border-color: transparent;

  background-color: #0072c6;

  box-shadow: none !important;
}

.navbar-default .navbar-collapse {
  max-height: none;

  padding: 0;

  border: none;
  box-shadow: none;
}

.navbar-default .navbar-nav {
  margin: -5px 0 0 0;
}

.navbar-default .navbar-nav:first-child {
  margin: 0;
}

.navbar-default .navbar-brand,
.navbar-default .navbar-brand:hover {
  color: #fff;
}

.navbar-default .navbar-toggle {
  border-color: #fff;

  background-color: #7fb8e2;
}

.navbar-default .navbar-toggle:active,
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #0072c6;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}

.sidebar-nav .navbar li a,
.sidebar-nav .navbar li a:link,
.sidebar-nav .navbar li a:visited {
  margin: 5px;
  padding: 10px;

  color: #fff;
  font-size: 16px;
  font-weight: 700;

  border: 1px solid #fff;
}

.sidebar-nav .navbar li a:active,
.sidebar-nav .navbar li a:focus,
.sidebar-nav .navbar li a:hover {
  color: #fff;

  background-color: #0067b0;
}

.sidebar-nav .navbar li.active a,
.sidebar-nav .navbar li.active a:link,
.sidebar-nav .navbar li.active a:visited {
  color: #0072c6;

  background-color: #fff;
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .navbar-default .navbar-nav {
    margin: 0;
  }
}

@media (min-width: 992px) {
  /*.sidebar-nav {
		margin:				0 -15px 0 0;
	}*/

  .navbar-nav,
  .sidebar-nav .navbar li {
    float: none;
  }

  .sidebar-nav .navbar li a {
    display: block;
  }
}

@media (min-width: 992px) and (min-height: 800px) {
  .sidebar-nav {
    position: fixed;
    top: 0;
    left: 0;

    width: 16.66666667%;
  }

  .sidebar-nav .navbar-default {
    margin: 15px -10px 15px 15px;
  }
}
