#page-container {
  margin: 15px 0 0;
  min-height: 500px;
}

@media (min-width: 992px) and (min-height: 800px) {
  #page-container {
    margin-top: 160px;
  }

  #notes-container {
    top: 160px;
  }

  .sidebar-nav {
    top: 145px;
  }
}
