@import "theme-variables";

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
.btn-success,
.btn-success:disabled,
.btn-success:focus,
.btn-success:hover {
  color: $white;
}
